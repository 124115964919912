<template>
  <div v-if="market.id" class="cbo-wrapper wrapper--md-primary">
    <Container>
      <div class="cbo-summary">
        <h1 class="summary-title cbo-title-2">
          {{ $strings.processes_title }}
        </h1>
        <span class="summary-icon">
          <img :src="market.logo" alt="" />
        </span>
        <span class="summary-label">
          {{ market.name }}
        </span>
      </div>
    </Container>

    <div class="cbo-summary-angle">
      <v-icon color="white"> $vuetify.icons.values.angle </v-icon>
    </div>

    <Container class="cbo-process" white full-height>
      <h2 class="process-title">
        {{ $strings.processes_subtitle }}
      </h2>

      <div class="process-list hidden-md-and-up">
        <carousel
          :per-page="1"
          :pagination-enabled="false"
          class="list-carousel"
        >
          <slide
            v-for="(processus, i_processus) in processesSelection"
            :key="'processus_' + i_processus"
          >
            <router-link
              :to="{
                name: 'problems',
                params: { market: market.id, processus: processus.id }
              }"
              class="list-el"
            >
              <span class="el-picture">
                <img v-if="processus.logo" :src="processus.logo.file" alt="" />
              </span>
              <span class="el-label">
                {{ processus.title }}
              </span>
              <span class="el-icon">
                <v-icon color="white"> $vuetify.icons.values.angle </v-icon>
              </span>
            </router-link>
          </slide>
        </carousel>
      </div>

      <div class="process-map hidden-sm-and-down"
>        <div class="map-picture">
          <img v-if="market.image_process" :src="market.image_process" alt="" />
        </div>
        <div class="map-pointers">
          <div
            v-for="(processus, i_processus) in processesSelection"
            :key="'processus_' + i_processus"
            :style="pointerPositions(processus)"
            :class="pointerClasses(processus.id)"
            @click="switchPointer(processus.id)"
          >
            <router-link
              :to="{
                name: 'problems',
                params: { market: market.id, processus: processus.id }
              }"
              class="el-label"
            >
              {{ processus.title }}
            </router-link>
            <span class="el-icon">
              <v-icon color="white"> $vuetify.icons.values.more </v-icon>
            </span>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Container from "@/components/Container";

export default {
  name: "Markets",
  components: {
    Container
  },
  data: () => ({
    activePointer: null
  }),
  computed: {
    ...mapGetters({
      markets: "markets/items",
      market: "markets/item",
      processes: "processes/items",
      processesSelection: "processes/selection"
    })
  },
  watch: {
    markets: {
      handler: function() {
        this.$store.dispatch("markets/find", this.$route.params.market);
      },
      immediate: true
    },
    processes: {
      handler: function() {
        this.$store.dispatch("processes/select", this.$route.params.market);
      },
      immediate: true
    }
  },
  methods: {
    switchPointer(id) {
      this.activePointer = this.activePointer !== id ? id : null;
    },
    pointerClasses(id) {
      return ["pointers-el", { active: this.activePointer === id }];
    },
    pointerPositions(processus) {
      const top = processus.position_top ? processus.position_top : 0;
      const left = processus.position_left ? processus.position_left : 0;
      return "top:" + top + "%; left:" + left + "%;";
    }
  }
};
</script>

<style lang="scss" scoped>
.cbo-process {
  .process-list {
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 0 30% 6.5rem 30%;

    .list-carousel {
      overflow: visible !important;

      &::v-deep {
        .VueCarousel-wrapper,
        .v-responsive {
          overflow: visible !important;
        }
      }
    }

    .list-el {
      display: block;
      border-radius: 0.5rem;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
      background: white;
      text-decoration: none;
      padding: 1rem 0;
      margin: 0 1rem 0 0;
      position: relative;

      .el-picture {
        display: block;
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-bottom: 79%;

        img {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          object-fit: cover;
          object-position: center;
        }
      }

      .el-label {
        display: block;
        color: var(--v-primary-base);
        font-size: 0.94rem;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
        line-height: 1.2em;
        text-align: center;
        margin: 2rem 0 1.5rem 0;
        padding: 0 1rem;
      }

      .el-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.69rem;
        height: 1.69rem;
        position: absolute;
        bottom: 0;
        left: 50%;
        background: var(--v-accent-base);
        border-radius: 50%;
        transform: translateX(-50%) translateY(50%);

        .v-icon {
          font-size: 0.75rem;
          width: 0.75rem;
          height: 0.75rem;
        }
      }
    }
  }

  .process-map {
    box-shadow: 0 0 0.94rem rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    margin-bottom: 6.5rem;
    background-color: white;
    position: relative;
    overflow: hidden;

    .map-picture {
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .map-pointers {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .pointers-el {
        display: block;
        position: absolute;

        .el-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--v-accent-base);
          width: 1.38rem;
          height: 1.38rem;
          border-radius: 50%;
          cursor: pointer;
          transition: transform 0.2s ease-in-out;

          .v-icon {
            font-size: 0.88rem;
            width: 0.88rem;
            height: 0.88rem;
            transform: rotate(90deg);
          }
        }

        .el-label {
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%);
          background: var(--v-accent-base);
          border-radius: 0.94rem;
          text-align: center;
          color: white;
          font-weight: bold;
          font-size: 0.94rem;
          line-height: 1.2em;
          width: 9.5rem;
          padding: 1.5rem 1rem;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        &.active {
          z-index: 1;

          .el-icon {
            transform: rotate(45deg);
          }
          .el-label {
            display: block;
          }
        }
      }
    }
  }
}
</style>
