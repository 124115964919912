import Vue from "vue";
import axios from "@/plugins/axios";

// initial state
const state = () => ({
  items: [],
  item: {},
  selection: [] // selection of processes depending on current market
});

// getters
const getters = {
  items: (state) => state.items,
  item: (state) => state.item,
  selection: (state) => state.selection
};

// actions
const actions = {
  reset({ commit }) {
    commit("SET_ITEMS", []);
    commit("SET_ITEM", {});
    commit("SET_SELECTION", []);
  },

  fetch({ commit }) {
    return new Promise((success, failure) => {
      axios
        .post("/", {
          query: `
          {
            allprocessus (fname: "marche") {
              entity {
                id
                entity_type
                language
                title
                marche {
                  id
                }
                logo {
                  file
                }
                subProblemes {
                  id
                }
                position_top
                position_left
              }
            }
          }
          `
        })
        .then((response) => {
          const processes = [];
          if (response.data.data && response.data.data.allprocessus) {
            response.data.data.allprocessus.entity.forEach((processus) => {
              if (processus.language === Vue.prototype.$lang)
                processes.push(processus);
            });
          }
          commit("SET_ITEMS", processes);
          success(processes);
        })
        .catch((error) => {
          console.log(error);
          failure(error);
        });
    });
  },

  find({ commit, state }, id) {
    const item = state.items.find(function(processus) {
      if (processus.id === id) return processus;
    });
    commit("SET_ITEM", item || {});
  },

  select({ commit, state }, market_id) {
    const items = [];
    state.items.forEach((processus) => {
      if(processus.marche){
        processus.marche.forEach((market) => {
          if (market.id === parseInt(market_id)) items.push(processus);
        });
      }
    });
    commit("SET_SELECTION", items);
  }
};

// mutations
const mutations = {
  SET_ITEMS(state, value) {
    state.items = value;
  },
  SET_ITEM(state, value) {
    state.item = value;
  },
  SET_SELECTION(state, value) {
    state.selection = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
