<template>
  <div class="cbo-wrapper">
    <Container>
      <div class="cbo-summary">
        <h1 class="summary-title cbo-title-2">
          {{ $strings.products_title }}
        </h1>
        <div class="summary-market">
          <span v-if="market.logo" class="market-icon">
            <img :src="market.logo" alt="" />
          </span>
          <span class="market-label">
            {{ market.name }}
          </span>
        </div>
        <div
          v-if="market && processus.id && routeProblems"
          class="summary-breadcrumbs"
        >
          <router-link
            :to="{
              name: 'processes',
              params: { market: market.id }
            }"
          >
            {{ processus.title }}
          </router-link>
          <span
            v-for="(problem, index) in routeProblems"
            :key="'bc_problem_' + index"
          >
            <span v-if="index % 2 === 0" class="sep">&gt;</span>
            <router-link
              :to="{
                name: 'problems',
                params: { market: market.id, processus: processus.id }
              }"
              class="bc"
            >
              {{ problem.title }}
            </router-link>
          </span>
        </div>
      </div>
    </Container>

    <div class="cbo-summary-angle">
      <v-icon color="white"> $vuetify.icons.values.angle </v-icon>
    </div>

    <Container class="cbo-products" white>
      <h2 class="products-title">
        {{ $strings.products_subtitle }}<br />
        <small>
          {{ productsSelection.length }}
          {{ $strings.products_results }}
        </small>
      </h2>

      <div class="products-list">
        <carousel
          :per-page-custom="[
            [0, 1],
            [768, 2],
            [1024, 4]
          ]"
          :paginationPadding="8"
          paginationColor="#003761"
          paginationActiveColor="#e77a24"
          centerMode
          class="list-carousel"
        >
          <slide
            v-for="(product, index) in productsSelection"
            :key="'product_' + index"
          >
            <div class="list-el">
              <div class="el-picture">
                <img
                  v-if="product.mediacollection && product.mediacollection[0]"
                  :src="getFirstPicture(product.mediacollection)"
                  alt=""
                />
              </div>
              <div class="el-content">
                <div class="content-title">{{ product.title }}</div>
                <Button
                  class="content-button"
                  @click.native="openProduct(product)"
                >
                  {{ $strings.products_button_knowmore }}
                </Button>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </Container>

    <v-navigation-drawer
      :value="productSelected !== null"
      width="100%"
      height="100vh"
      fixed
      bottom
      hide-overlay
      touchless
      stateless
      class="cbo-product-drawer"
    >
      <HeaderFake />
      <div class="drawer-inner">
        <Container v-if="productSelected" class="cbo-product cbo-cms" white>
          <button class="product-close" @click="productSelected = null">
            <v-icon color="primary">$vuetify.icons.values.cross</v-icon>
          </button>
          <div
            v-if="
              productSelected.gammeProduit && productSelected.gammeProduit[0]
            "
            class="product-subtitle cbo-title-3"
          >
            {{ productSelected.gammeProduit[0].name }}
          </div>
          <div v-if="productSelected.title" class="product-title cbo-title-1">
            {{ productSelected.title }}
          </div>
          <div
            v-if="productSelected.accroche"
            v-html="productSelected.accroche"
            class="product-chapo chapo"
          ></div>
          <div
            v-if="productSelected.texteIntro"
            v-html="productSelected.texteIntro"
            class="product-desc"
          ></div>
          <div class="product-buttons">
            <Button
              v-if="productSelected.video"
              color="accent"
              @click.native="modalVideo = true"
            >
              {{ $strings.product_button_video }}
            </Button>
            <Button
              :to="{ name: 'product', params: { product: productSelected.id } }"
            >
              {{ $strings.product_button_details }}
            </Button>
          </div>
          <div v-if="productSelected.avantages" class="product-advantages">
            <div class="advantages-title cbo-title-2">
              {{ $strings.product_title_advantages }}
            </div>
            <div v-html="productSelected.avantages"></div>
          </div>
          <div v-if="productSelected.references" class="product-references">
            <div class="references-title cbo-title-2">
              {{ $strings.product_title_references }}
            </div>
            <div class="references-carousel">
              <carousel
                :per-page="1"
                :paginationPadding="8"
                paginationColor="#003761"
                paginationActiveColor="#e77a24"
              >
                <slide
                  v-for="(reference, i_reference) in productSelected.references"
                  :key="'reference_' + i_reference"
                >
                  <div
                    v-if="reference.logo && reference.logo.url"
                    class="carousel-el"
                  >
                    <img :src="reference.logo.url" :alt="reference.name" />
                  </div>
                </slide>
              </carousel>
            </div>
          </div>
        </Container>
      </div>
    </v-navigation-drawer>
    <v-dialog v-model="modalVideo" v-if="modalVideo" max-width="30rem">
      <div class="cbo-video">
        <Video
          :src="productSelected.video.url"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Container from "@/components/Container";
import Button from "@/components/Button";
import Video from "@/components/Video";
import HeaderFake from "@/components/HeaderFake";

export default {
  name: "Products",
  components: {
    Container,
    Button,
    HeaderFake,
    Video
  },
  computed: {
    ...mapGetters({
      markets: "markets/items",
      market: "markets/item",
      processes: "processes/items",
      processus: "processes/item",
      problems: "problems/items",
      products: "products/items",
      productsSelection: "products/selection"
    })
  },
  watch: {
    "$route.params.problem": {
      handler: function(problems) {
        this.routeProblemsIds = problems;
      },
      deep: true,
      immediate: true
    },
    markets: {
      handler: function() {
        this.$store.dispatch("markets/find", this.$route.params.market);
      },
      immediate: true
    },
    processes: {
      handler: function() {
        this.$store.dispatch("processes/find", this.$route.params.processus);
      },
      immediate: true
    },
    problems: {
      handler: function(problems) {
        if (this.routeProblemsIds && problems)
          this.getProblems(this.routeProblemsIds);
      },
      immediate: true
    },
    products: {
      handler: function() {
        if (this.routeProblem) {
          this.$store.dispatch("products/selectByProblem", this.routeProblem);
        }
      },
      immediate: true
    },
    routeProblem: {
      handler: function() {
        if (this.products && this.products.length > 0) {
          this.$store.dispatch("products/selectByProblem", this.routeProblem);
        }
      }
    }
  },
  data: () => ({
    productDrawer: false,
    productSelected: null,
    routeProblemsIds: null,
    routeProblems: [],
    routeProblem: null,
    modalVideo: false
  }),
  methods: {
    openProduct(product) {
      this.productSelected = null;
      this.$nextTick(() => {
        this.productSelected = product;
      });
    },
    getProblem(id) {
      const problem = this.$store.state.problems.items.find((problem) => {
        return problem.id === id;
      });
      return problem;
    },
    getProblems(ids) {
      const problems = [];
      ids.split("-").forEach((id) => {
        const problem = this.getProblem(id);
        if (problem) problems.push(problem);
      });
      this.routeProblems = problems;
      this.routeProblem = this.routeProblems[this.routeProblems.length - 1];
    },
    getFirstPicture( collection ){
      let picture = null;
      collection.forEach((media) => {
        if(!picture && media.url && media.url.substring(media.url.length - 3) !== 'mp4'){
          picture = media.url
        }
      });
      return picture;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

.cbo-products {
  .products-list {
    margin-top: 1rem;
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 0 30% 6.5rem 0;

    .list-carousel {
      overflow: visible !important;

      &::v-deep {
        .VueCarousel-wrapper,
        .v-responsive {
          overflow: visible !important;
        }

        .VueCarousel-pagination{
          transform:translateX(20%);
        }
      }
    }

    .list-el {
      display: flex;
      flex-direction: column;
      border-radius: 0.5rem;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
      background: white;
      text-decoration: none;
      padding: 0;
      margin: 0 1.5rem 0 0;
      overflow: hidden;
      position: relative;
      min-height: 100%;

      .el-picture {
        display: block;
        position: relative;
        width: 100%;
        padding-bottom: 60%;

        img {
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .el-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        text-align: center;
        padding: 1.5rem 1rem;

        .content-title {
          color: var(--v-primary-base);
          font-size: 1rem;
          font-weight: 700;
          margin: 0 0 1rem 0;
        }
      }
    }
  }
}

.cbo-product-drawer {
  max-height: 100vh !important;
  background: var(--v-primary-base) !important;
}

.cbo-product {
  display: flex;
  align-items: stretch;
  min-height: 100%;
  min-height: calc(100% - 5.25rem);
  padding-top: 1.8rem;
  position: relative;

  &::v-deep {
    & > .cbo-container {
      display: flex;
      flex-direction: column;
      //min-height:100%;
    }
  }

  .product-close {
    border: none;
    background: none;
    color: var(--v-primary-base);
    font-size: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  .product-subtitle {
    margin-top: 0;
    padding-right: 3rem;
  }

  .product-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    .cbo-button {
      width: 48%;
    }
  }

  .product-advantages {
    padding-bottom:6rem;
  }

  .product-references {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: #f5f5f5;
    margin: 0 -2rem;

    .references-title {
      padding: 1.5rem 2rem 0 2rem;
    }

    .references-carousel {
      padding: 0 30% 7rem 30%;

      &::v-deep {
        .VueCarousel-wrapper,
        .v-responsive {
          overflow: visible !important;
        }
      }

      .carousel-el {
        display: block;
        padding: 0 0 40% 0;
        margin: 0 1rem;
        position: relative;

        img {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .cbo-products {
    .products-list {
      padding: 0 0 6.5rem 0;

      &::v-deep {
        .VueCarousel-pagination{
          transform:none;
        }
      }
    }
  }

  .cbo-product {
    min-height: calc(100% - 7.75rem);
    border-radius: 2rem;
    overflow-x: hidden;
    overflow-y: auto;

    .product-advantages {
      padding-bottom:2rem;
    }

    .product-references {
      margin: 0 -4rem;

      .references-title {
        padding-left: 4rem;
        padding-right: 4rem;
      }

      .references-carousel {
        padding-bottom: 4rem;

        &::v-deep {
          .VueCarousel-pagination {
            .VueCarousel-dot-container {
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    &::v-deep {
      & > .cbo-container {
        padding-bottom: 0;
      }
    }
  }

  .cbo-product-drawer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 64, 99, 0.93) !important;

    &::v-deep {
      .v-navigation-drawer__content {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .cbo-headerfake {
      display: none;
    }

    .drawer-inner {
      width: 37.5rem;
      height: 100%;
      max-height: 75vh;
    }
  }
}
</style>
