<template>
  <div class="cbo-wrapper wrapper--md-info">
    <Container primary class="cbo-introduction-wrapper" noRadius>
      <div class="cbo-introduction" style="padding-top:1rem">
        <h1>{{ category.name }}</h1>
      </div>
    </Container>

    <Container class="cbo-category" white full-height noRadius>
      <div class="category-list">
        <div
          class="list-el"
          v-for="(product, index) in productsSelection"
          :key="'product_' + index"
        >
          <div class="el-picture">
            <v-img
                v-if="product.mediacollection && product.mediacollection[0]"
                :src="getFirstPicture(product.mediacollection)"
                alt=""
              />
          </div>
          <div class="el-content">
            <div class="content-title">{{ product.title }}</div>
            <div
              class="content-text"
              v-if="product.texteIntro"
              v-html="product.texteIntro"
            ></div>
            <div class="content-button">
              <Button
                :to="{ name: 'product', params: { product: product.id } }"
                color="accent"
              >
                {{ $strings.products_button_discover }}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Container from "@/components/Container";
import Button from "@/components/Button";

export default {
  name: "Category",
  components: {
    Container,
    Button
  },
  computed: {
    ...mapGetters({
      categories: "categories/items",
      category: "categories/item",
      products: "products/items",
      productsSelection: "products/selection"
    })
  },
  watch: {
    categories: {
      handler: function() {
        this.$store.dispatch("categories/find", this.$route.params.category);
      },
      immediate: true
    },
    category: {
      handler: function() {
        this.$store.dispatch(
          "products/selectByCategory",
          this.$route.params.category
        );
      },
      immediate: true
    },
    products: {
      handler: function() {
        this.$store.dispatch(
          "products/selectByCategory",
          this.$route.params.category
        );
      },
      immediate: true
    }
  },
  methods: {
    getFirstPicture( collection ){
      let picture = null;
      collection.forEach((media) => {
        if(!picture && media.url && media.url.substring(media.url.length - 3) !== 'mp4'){
          picture = media.url
        }
      });
      return picture;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

.cbo-category {
  .category-list {
    padding-top: 3rem;
    padding-bottom: 4.5rem;

    .list-el {
      position: relative;
      margin-bottom: 4rem;

      .el-picture {
        display: block;
        position: relative;
        width: 100%;
        padding-bottom: 100%;

        .v-image,
        img {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .el-content {
        position: absolute;
        bottom: 0;
        left: 0;
        background: var(--v-primary-base);
        color: white;
        width: 80%;
        padding: 2rem;
        font-size: 1.5rem;
        line-height: 1em;
        font-weight: 700;

        &:after {
          content: "";
          display: block;
          width: 2.31rem;
          height: 2.31rem;
          position: absolute;
          top: 50%;
          right: -1.155rem;
          margin-top: -1.155rem;
          background: url(../assets/images/plus-orange.svg) no-repeat center;
          background-size: contain;
        }

        .content-text {
          display: none;
        }

        .content-button {
          position: absolute;
          bottom: 0;
          left: 2rem;
          transform: translateY(50%);
        }
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .cbo-summary .summary-label {
    margin-top: 2rem;
  }

  .cbo-category {
    .category-list {
      .list-el {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .el-picture {
          padding-bottom: 36.48%;
          width: 48%;
        }

        .el-content {
          background: none;
          color: var(--v-primary-base);
          font-size: 2.5rem;
          position: relative;
          left: auto;
          bottom: auto;
          width: 48%;

          &:after {
            content: none;
            display: none;
          }

          .content-text {
            display: block;
            font-size: 0.75rem;
            line-height: 1.67em;
            font-weight: normal;
            margin-top: 1rem;
          }

          .content-button {
            position: relative;
            left: auto;
            bottom: auto;
            transform: none;
            margin-top: 1rem;
          }
        }

        &:nth-child(even) {
          flex-direction: row-reverse;

          .el-content {
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
