import Vue from "vue";
import axios from "@/plugins/axios";

// initial state
const state = () => ({
  items: [],
  item: {}
});

// getters
const getters = {
  items: (state) => state.items,
  item: (state) => state.item
};

// actions
const actions = {
  reset({ commit }) {
    commit("SET_ITEMS", []);
    commit("SET_ITEM", {});
  },

  // Fetch all available categories from API
  fetch({ commit }) {
    return new Promise((success, failure) => {
      axios
        .post("/", {
          query: `
            {
              taxogammes(parent:0) {
                gamme {
                  id
                  name
                  language
                }
              }
            }
            `
        })
        .then((response) => {
          const categories = [];
          if (response.data.data && response.data.data.taxogammes) {
            response.data.data.taxogammes.gamme.forEach((category) => {
              if (category.language === Vue.prototype.$lang)
                categories.push(category);
            });
          }
          commit("SET_ITEMS", categories);
          success(categories);
        })
        .catch((error) => {
          console.log(error);
          failure(error);
        });
    });
  },

  // Find a specific category by id in store
  find({ commit, state }, id) {
    const item = state.items.find(function(category) {
      if (category.id == id) return category;
    });
    commit("SET_ITEM", item || {});
  }
};

// mutations
const mutations = {
  SET_ITEMS(state, value) {
    state.items = value;
  },
  SET_ITEM(state, value) {
    state.item = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
